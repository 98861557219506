<template>
  <div>
    <NForm inline ref="searchFormRef" label-placement="left">
      <NFormItem label="搜索：">
        <NInput
          placeholder="请输入学校名称"
          maxlength="30"
          show-count
          clearable
          v-model:value="searchParams.school_name"
          @keyup.enter="handleSearch"
        />
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>
    <NDataTable
      size="small"
      :columns="columns"
      :row-key="row => row.id"
      :data="tableData"
      remote
      :pagination="pagination"
      @update:page="handlePageChange"
      @update:page-size="handlePageSizeChange"

      :default-checked-row-keys="selectedIdsArr"
      @update:checked-row-keys="handleSelect"
    />
    <NSpace justify="end" style="margin: 12px -28px 0; padding: 15px 28px 0; border-top: 1px solid #ddd;">
      <span style="line-height: 34px; color: #999;">已选：<span class="theme-color">{{ selectedIdsArr.length }}</span></span>
      <NButton v-if="fixContent" @click="emit('modal-close')">取消</NButton>
      <NButton v-else @click="preStep">上一步</NButton>
      <NButton
        type="primary"
        :disabled="selectedIdsArr.length === 0"
        @click="nextStep"
      >下一步</NButton>
    </NSpace>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, defineExpose, onMounted } from 'vue';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { getSchoolList } from '@/api/school.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  const { SUCCESS } = resStatusEnum;

  const loading = ref(false);
  const emit = defineEmits(['modal-close', 'step-pre', 'step-next', 'id-school-update']);
  const props = defineProps({
    fixContent: {
      type: Boolean,
      default: false
    }
  });

  const preStep = () => {
    emit('step-pre');
  };
  const nextStep = () => {
    emit('step-next');
  };
  const resetStep = () => {
    handleSelect([]);
    tableData.value.splice(0);
  };

  const searchFormRef = ref(null);
  const getDefaultSearchParams = () => ({
    school_name: ''
  });
  const searchParams = reactive(getDefaultSearchParams());
  const getSearchParams = () => {
    const params = {};
    Object.keys(searchParams).forEach(key => {
      const value = searchParams[key];
      value && (params[key] = value);
    });
    return params;
  };
  const handleSearch = () => {
    handlePageChange(1);
  };
  const handleClear = () => {
    Object.assign(searchParams, getDefaultSearchParams());
    handleSearch();
  };

  const columns = [
    { type: 'selection' },
    { title: '学校名称', key: 'school_name' },
    { title: '学生数', key: 'student_num' },
    { title: '授课教师数', key: 'teacher_num' },
  ];
  const tableData = ref([]);
  const pagination = reactive({
    page: 1,
    itemCount: 0,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true
  });
  const updateItemCount = count => {
    pagination.itemCount = Number(count) || 0;
  };
  const handlePageChange = (page) => {
    pagination.page = page;
    updateList();
  };
  const handlePageSizeChange = (pageSize) => {
    pagination.pageSize = pageSize;
    handlePageChange(1);
  };
  const updateList = () => {
    loading.value = true;
    getSchoolList({
      ...getSearchParams(),
      page: pagination.page,
      page_size: pagination.pageSize
    }).then(res => {
      loading.value = false;
      if (res.code === SUCCESS) {
        tableData.value = res.data.list || [];
        updateItemCount(res.data.total);
        updateIdSchoolMap(tableData.value);
      }
    }).catch(err => {
      loading.value = false;
    });
  };
  const initTableData = () => {
    if (tableData.value.length === 0) {
      handleClear();
    }
  };

  const selectedIdsArr = ref([]);
  const idSchoolMap = {
    // id: school_name
  };
  const updateIdSchoolMap = (list) => {
    Array.isArray(list) && list.forEach(elem => {
      idSchoolMap[elem.id] = elem.school_name;
    });
  };
  const handleSelect = idsArr => {
    selectedIdsArr.value = idsArr;
    updateIdSchoolArr(idsArr);
  };
  const updateIdSchoolArr = idsArr => {
    /*
    {
      id: school_name
    }
    */
    const obj = {};
    Array.isArray(idsArr) && idsArr.forEach(id => {
      obj[id] = idSchoolMap[id];
    });
    emit('id-school-update', obj); 
  };

  defineExpose({
    resetStep,
    initTableData
  });
</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";
  .theme-color {
    color: @theme-color;
  }
</style>