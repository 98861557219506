<template>
  <div>
    <NForm inline ref="searchFormRef" label-placement="left">
      <NFormItem label="搜索：">
        <NInput
          maxlength="20"
          show-count
          clearable
          v-model:value="searchParams.title"
          @keyup.enter="handleSearch"
        />
      </NFormItem>
      <NFormItem>
        <NSpace>
          <NButton type="primary" @click="handleSearch">搜索</NButton>
          <NButton @click="handleClear">清除</NButton>
        </NSpace>
      </NFormItem>
    </NForm>
    <NDataTable
      size="small"
      :columns="columns"
      :row-key="row => row.id"
      :data="tableData"
      remote
      :pagination="pagination"
      @update:page="handlePageChange"
      @update:page-size="handlePageSizeChange"

      :default-checked-row-keys="selectedIdsArr"
      @update:checked-row-keys="handleSelect"
    ></NDataTable>
    <NSpace justify="end" style="margin: 12px -28px 0; padding: 15px 28px 0; border-top: 1px solid #ddd;">
      <span style="line-height: 34px; color: #999;">已选：<span class="theme-color">{{ selectedIdsArr.length }}</span></span>
      <NButton @click="emit('modal-close')">取消</NButton>
      <NButton
        type="primary"
        :disabled="selectedIdsArr.length === 0"
        @click="nextStep"
      >下一步</NButton>
    </NSpace>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, reactive, onMounted, defineExpose } from 'vue';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { getLabelList } from '@/api/label.js';
  import { resStatusEnum } from '@/enumerators/http.js';
  const { SUCCESS } = resStatusEnum;

  const loading = ref(false);
  const emit = defineEmits(['step-next', 'id-label-update', 'modal-close']);
  const props = defineProps({
    labelType: {
      default: ''
    }
  });

  const nextStep = () => {
    emit('step-next');
  };
  const resetStep = () => {
    handleSelect([]);
  };
  defineExpose({
    handleClear,
    resetStep
  });

  const getDefaultSearchParams = () => ({
    title: ''
  });
  const searchFormRef = ref(null);
  const searchParams = reactive(getDefaultSearchParams());
  const getSearchParams = () => {
    const params = {};
    Object.keys(searchParams).forEach(key => {
      const value = searchParams[key];
      value && (params[key] =  value);
    });
    return params;
  };
  const handleSearch = () => {
    handlePageChange(1);
  };
  const handleClear = () => {
    Object.assign(searchParams, getDefaultSearchParams());
    handleSearch();
  };
  const tableData = ref([]);
  const columns = [
    { type: 'selection' },
    { title: '标签', key: 'title' },
    { title: '所含文件数', key: 'file_num' },
    { title: '已分发学校数', key: 'distribute_school_num' },
  ];
  const pagination = reactive({
    page: 1,
    itemCount: 0,
    pageSize: 10,
    pageSizes: [10, 20, 30],
    showSizePicker: true
  });
  const updateItemCount = count => {
    pagination.itemCount = Number(count) || 0;
  };
  const handlePageChange = (page) => {
    pagination.page = page;
    updateList();
  };
  const handlePageSizeChange = (pageSize) => {
    pagination.pageSize = pageSize;
    handlePageChange(1);
  };
  const updateList = () => {
    loading.value = true;
    getLabelList({
      type: props.labelType,
      ...getSearchParams(),
      page: pagination.page,
      page_size: pagination.pageSize
    }).then(res => {
      loading.value = false;
      if (res.code === SUCCESS) {
        tableData.value = res.data.list || [];
        updateItemCount(res.data.total);
        updateIdLabelMap(tableData.value);
      }
    }).catch(err => {
      loading.value = false;
    });
  };
  onMounted(() => {
    handleClear();
  });
  const selectedIdsArr = ref([]);
  const idLabelMap = {
    // id: title
  };
  const updateIdLabelMap = (list) => {
    Array.isArray(list) && list.forEach(elem => {
      idLabelMap[elem.id] = elem.title;
    });
  };
  const updateIdLabelArr = (idsArr) => {
    /*
    {
      id: label
    }
    */
    const obj = {};
    Array.isArray(idsArr) && idsArr.forEach(id => {
      obj[id] = idLabelMap[id];
    });
    emit('id-label-update', obj);
  };
  const handleSelect = idsArr => {
    selectedIdsArr.value = idsArr;
    updateIdLabelArr(idsArr);
  };

</script>

<style lang="less" scoped>
  @import "~@/styles/variables.less";
  .theme-color {
    color: @theme-color;
  }
</style>