<template>
  <div>
    <NForm label-placement="left" :label-width="130">
      <NGrid v-if="fixContent" :x-gap="20" :cols="24">
        <NFormItemGi
          :span="8"
          v-for="item in distributeContents"
          :key="item.id"
        >
          <div class="content-item">
            <NFormItem>
              <template #label>
                <span style="color: #bbb;">教材ID：</span>
              </template>
              <template #default>{{ item.id }}</template>
            </NFormItem>
            <NFormItem>
              <template #label>
                <span style="color: #bbb;">教材名称：</span>
              </template>
              <template #default>
                <div class="text-ellipsis" :title="item.title">{{ item.title }}</div>
              </template>
            </NFormItem>
            <NFormItem>
              <template #label>
                <span style="color: #bbb;">创建人：</span>
              </template>
              <template #default>
                <div class="text-ellipsis" :title="item.admin_username">{{ item.admin_username }}</div>
              </template>
            </NFormItem>
          </div>
        </NFormItemGi>
      </NGrid>
      <NFormItem v-else>
        <template #label>
          <span style="color: #bbb;">标签：</span>
        </template>
        <template #default>{{ selectedLabelsStr }}</template>
      </NFormItem>
      <NFormItem>
        <template #label>
          <span style="color: #bbb;">已选分发学校：</span>
        </template>
        <template #default>{{ selectedSchoolsStr }}</template>
      </NFormItem>
      <NFormItem>
        <template #label>
          <span style="color: #bbb;">总计：</span>
        </template>
        <template #default>
          <span v-if="fixContent">{{ distributeContents.length }} 个{{ contentTitle }}，</span>
          <span v-else>{{ Object.keys(idLabel).length }} 个标签，</span>
          <span>{{ Object.keys(idSchool).length }} 所学校</span>
        </template>
      </NFormItem>
    </NForm>

    <NSpace justify="end" style="margin: 12px -28px 0; padding: 15px 28px 0; border-top: 1px solid #ddd;">
      <NButton @click="emit('modal-close')">取消</NButton>
      <NButton @click="preStep">上一步</NButton>
      <NButton type="primary" @click="handleSubmit">确定</NButton>
    </NSpace>

    <PageLoading :loading="loading" />
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';

  import PageLoading from '@/components/PageLoading/index.vue';

  import { resStatusEnum } from '@/enumerators/http.js';
  const { SUCCESS } = resStatusEnum;

  const loading = ref(false);
  const props = defineProps({
    fixContent: {
      type: Boolean,
      default: false
    },
    /*
    [
      {
        id,
        title,
        admin_username
      }
    ]
    */
    distributeContents: {
      type: Array,
      default: () => []
    },
    contentTitle: {
      type: String,
      default: ''
    },
    idLabel: {
      type: Object,
      default: () => {
        return {};
      }
    },
    idSchool: {
      type: Object,
      default: () => {
        return {};
      }
    },
    distributeFn: {
      type: Function,
      required: true
    }
  });
  const emit = defineEmits(['step-pre', 'modal-close', 'distribute-success']);

  const selectedLabelsStr = computed(() => {
    const idLabel = props.idLabel;
    return Object.keys(props.idLabel).map(id => idLabel[id]).join('、');
  });
  const selectedSchoolsStr = computed(() => {
    const idSchool = props.idSchool;
    return Object.keys(idSchool).map(id => idSchool[id]).join('、');
  });

  const preStep = () => {
    emit('step-pre');
  };
  const handleSubmit = () => {
    loading.value = true;
    let ids;
    if (props.fixContent) {
      ids = props.distributeContents.map(({ id }) => id);
    } else {
      ids = Object.keys(props.idLabel);
    }
    props.distributeFn({
      'Data[ids]': ids,
      'Data[schools]': Object.keys(props.idSchool)
    }).then(res => {
      loading.value = false;
      if (res.code === SUCCESS) {
        window.$message.success('分发成功');
        emit('distribute-success');
        emit('modal-close');
      }
    }).catch(err => {
      loading.value = false;
    });
  };
</script>

<style lang="less" scoped>
  .content-item :deep(.n-form-item-feedback-wrapper) {
    display: none;
  }

  .text-ellipsis {
    text-overflow: ellipsis;
    overflow-x: hidden;
  }
</style>